
  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "Button",
    props: {
      hasIcon: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      secondary: {
        type: Boolean,
        required: false,
        default: false
      },
      textOnly: {
        type: Boolean,
        required: false,
        default: false
      },
      imageSrc: {
        type: String,
        required: false,
        default: ""
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      size: {
        type: String,
        required: false,
        default: "medium",
        validator: (v: string) => ["small", "medium", "large"].includes(v)
      },
    },
    emits: ["click"],
    setup(props, { emit }) {
      return {
        clicked: () => {
          if (!props.disabled) {
            emit("click");
          }
        }
      };
    }
  });
