
  import { defineComponent, ref, useContext } from "@nuxtjs/composition-api";
  import { email, required } from "vuelidate/lib/validators";
  import { errors } from "../use/vuelidate";
  import { useApi } from "../use/useApi";

  export default defineComponent({
    name: "Unsubscribe",
    layout: "portal",

    setup() {
      const context = useContext();
      const { postUnauthApiData } = useApi(context);
      const emailAddress = ref(context.query.value.email as string);
      const showAlert = ref(false);
      const msgAlert = ref("");
      const alertType = ref("success");

      return {
        emailAddress,
        showAlert,
        msgAlert,
        alertType,
        postUnauthApiData
      };
    },
    validations: {
      emailAddress: {
        required,
        email
      }
    },
    computed: {
      emailErrors(this: any) {
        return errors(this.$v.emailAddress, "Email");
      }
    },
    methods: {
      async handleUnsubscribe(this: any) {
        const throwError = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        try {
          const response = await this.postUnauthApiData(
            "/unauth/unsubscribe",
            {
              email: this.emailAddress
            },
            throwError
          );

          if (response.success) {
            this.msgAlert = "You have been unsubscribed successfully";
            this.alertType = "success";
            this.showAlert = true;
          } else {
            this.msgAlert =
              "An error occurred while unsubscribing. Please try again later";
            this.showAlert = true;
            this.alertType = "error";
          }
        } catch (error: any) {
          this.msgAlert =
            error.response?.data ===
            "Too many requests, please try again later."
              ? error.response?.data
              : "An error occurred while unsubscribing. Please try again later";
          this.showAlert = true;
          this.alertType = "error";
        }
      }
    }
  });
