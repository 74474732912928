import { render, staticRenderFns } from "./SignIn.vue?vue&type=template&id=64e20893&scoped=true&lang=pug"
import script from "./SignIn.vue?vue&type=script&lang=ts"
export * from "./SignIn.vue?vue&type=script&lang=ts"
import style0 from "./SignIn.vue?vue&type=style&index=0&id=64e20893&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e20893",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsDialogInfo: require('/workspace/portal/hosting/components/_base/Dialog/DialogInfo.vue').default,LsButton: require('/workspace/portal/hosting/components/_base/Button/Button.vue').default,LsHrText: require('/workspace/portal/hosting/components/_base/HrText.vue').default,LsAlert: require('/workspace/portal/hosting/components/_base/Alert/Alert.vue').default,InternetTradingPolicyDetailsPopUp: require('/workspace/portal/hosting/components/InternetTradingPolicyDetailsPopUp.vue').default,LogoPortal: require('/workspace/portal/hosting/components/LogoPortal.vue').default})
