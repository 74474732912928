
  import {
    GoogleAuthProvider,
    OAuthProvider,
    signOut as firebaseSignOut,
    getAuth,
    sendSignInLinkToEmail,
    signInWithPopup,
    signInWithCustomToken as firebaseSignInWithCustomToken
  } from "firebase/auth";
  import {
    Ref,
    defineComponent,
    onMounted,
    reactive,
    ref,
    useContext
  } from "@nuxtjs/composition-api";
  import { email, required } from "vuelidate/lib/validators";
  import { errorStateComputed, errors } from "../../use/vuelidate";
  import { useInternetTradingPolicyInject } from "../../use/useInternetTradingPolicy";
  import firebaseApp from "../../plugins/firebase";
  import { getQueryValue, logger } from "@loadsure/utils";

  export default defineComponent({
    name: "SignIn",

    props: {
      accessDenied: {
        type: String,
        default: ""
      }
    },

    setup() {
      const context = useContext();
      const { $sentry } = context;
      const $fireAuth = getAuth(firebaseApp);
      const { route } = context;

      const { showITPDetails, viewITPDetails, verifyUserAcceptedVersion } =
        useInternetTradingPolicyInject(context);
      verifyUserAcceptedVersion();

      const signInError: Ref<string | boolean> = ref(false);
      const emailSuccessSent: Ref<boolean> = ref(false);
      const form: {
        email: string | null;
      } = reactive({ email: null });

      const processingEmailSignIn: Ref<boolean> = ref(false);

      let customWidth: number | undefined;

      const signInWithGoogle = () => {
        signInError.value = false;
        const provider = new GoogleAuthProvider();
        signInWithPopup($fireAuth, provider).catch((error) => {
          signInError.value = error.message;
          if (
            error.message !== "Firebase: Error (auth/popup-closed-by-user)." &&
            error.message !== "Firebase: Error (auth/cancelled-popup-request)."
          ) {
            $sentry?.captureException(error);
          }
        });
      };

      const signInWithMicrosoft = () => {
        signInError.value = false;
        const provider = new OAuthProvider("microsoft.com");
        provider.setCustomParameters({
          prompt: "select_account"
        });
        signInWithPopup($fireAuth, provider).catch((error) => {
          signInError.value = error.message;
          if (
            error.message !== "Firebase: Error (auth/popup-closed-by-user)." &&
            error.message !== "Firebase: Error (auth/cancelled-popup-request)."
          ) {
            $sentry?.captureException(error);
          }
        });
      };

      let backgroundUrl = "/backgrounds/login-truck-1.webp";
      const date: Date = new Date();
      const currentHour = date.getHours();
      let darkMode = true;
      let style = "";

      onMounted(() => {
        // clear any old data
        window.localStorage.removeItem("emailForSignIn");
      });

      function initBackground() {
        if (currentHour >= 18 || currentHour <= 5) {
          backgroundUrl = "/backgrounds/login-truck-3.webp";
        } else if (currentHour >= 6 && currentHour < 12) {
          backgroundUrl = "/backgrounds/login-truck-2.webp";
        } else if (currentHour >= 12 && currentHour <= 17) {
          backgroundUrl = "/backgrounds/login-truck-1.webp";
          darkMode = false;
        }

        if (
          window.matchMedia("(max-width: 768px)").matches &&
          window.matchMedia("(min-width: 576px)").matches
        ) {
          customWidth = 420;
        }

        if (window.screen.width <= 768) {
          if (backgroundUrl === "/backgrounds/login-truck-3.webp") {
            style += "background-position:0% 95%; ";
          }
          if (backgroundUrl === "/backgrounds/login-truck-1.webp") {
            style += "background-position:0% 80%; ";
          }
        }
      }

      initBackground();
      style += `background-image: url(${backgroundUrl});`;

      const signOut = () => {
        firebaseSignOut($fireAuth);
      };

      const signInWithEmail = (vuelidate: any) => {
        if (form.email) {
          vuelidate.form.$touch();
          signInError.value = false;

          if (!vuelidate.form.$invalid) {
            processingEmailSignIn.value = true;

            const actionCodeSettings = {
              // eslint-disable-next-line no-restricted-globals
              url: `${location.origin}/`,
              handleCodeInApp: true
            };

            const formEmail = form.email?.toLowerCase();

            return sendSignInLinkToEmail(
              $fireAuth,
              formEmail,
              actionCodeSettings
            )
              .then(() => {
                window.localStorage.setItem("emailForSignIn", formEmail);
                emailSuccessSent.value = true;
                processingEmailSignIn.value = false;
              })
              .catch((error) => {
                signInError.value = error.message;
                processingEmailSignIn.value = false;
                $sentry?.captureException(error);
              });
          }
        }
        document.getElementById("email-sign-in")?.focus();
        return undefined;
      };

      const signInWithCustomToken = (token: string) => {
        signInError.value = false;
        
        firebaseSignInWithCustomToken($fireAuth, token).catch((error) => {
          logger.error(error);
          signInError.value = error.message;
        });
      };

      const customToken = getQueryValue(route.value.query.customToken);

      if (customToken) {
        signInWithCustomToken(customToken);
      }

      return {
        showITPDetails,
        viewITPDetails,
        backgroundUrl,
        style,
        darkMode,
        signInWithGoogle,
        signInWithMicrosoft,
        form,
        customWidth,
        emailSuccessSent,
        signInError,
        signOut,
        $fireAuth,
        signInWithEmail,
        processingEmailSignIn
      };
    },
    validations: {
      form: {
        email: {
          required,
          email
        }
      }
    },

    head() {
      return {
        title: "Sign In"
      };
    },

    computed: {
      ...errorStateComputed,
      emailErrors() {
        return errors(this.$v.form.email, "Email");
      }
    }
  });
